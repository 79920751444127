<template>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters([
            'currentLocale',
            'isMobile'
        ]),
    },
    mounted() {
        this.init()
    },
    
    methods: {
        ...mapActions([
            "postSigninLine",
            "setUserData"
        ]),
        async init() {

            //let url = "http://localhost:9080/en/lineCallback?code=eBzDkDJ7Y5Mtpjj7xD0C&state=N3N4OGN5YnU5cnRVaGFzNDRLVnFKSWRBNmlaMDJUeDI%3D";
            let url = window.location.href;

            let code = this.$tools.getParameterByName('code', url);
            let nonce2 = localStorage.getItem("nonce");
            let currentLocale = localStorage.getItem("currentLocale");
            let callbackUrl = window.location.href.split("?")[0];
            let params = {
                code: code,
                nonce: nonce2,
                preferredLanguage: currentLocale === 'vn' ? 'vi' : currentLocale,
                callbackUrl: callbackUrl,
                lang: currentLocale === 'vn' ? 'vi' : currentLocale
            }
        
            const result = await this.postSigninLine(params);
              if (result.result.businessCode === 0) { //sucuess
                    this.setUserData(result.result.data);

                    let locale = `${result.result.data.preferredLanguage === 'vi' ? 'vn' : result.result.data.preferredLanguage}`;
           
                    let redirectUrl = `${window.location.protocol}//${window.location.host}/${locale}`;
                    if (this.$tools.mobileCheck()) {           
                        window.location.href = redirectUrl
                    } else {
                        window.opener.location = redirectUrl;
                        window.close();
                    }
                } else {
                    alert(result.result.message)
                }
        }
    },
}
</script>

<style>

</style>